import * as React from "react"
import CBAFramework from "../templates/CbaFramework"
import Layout from "../components/Layout"


const CbaFrameworkPage = () => {
  return (
    <>
      <Layout>
        <CBAFramework />
      </Layout>
    </>
  )
}

export default CbaFrameworkPage
